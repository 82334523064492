import React from 'react';
import styled from 'styled-components';
import Section from '../../components/section';
import Headline from '../../components/sitters/headline';
import * as HowItWorks from '../../components/sitters/how-it-works';
import Text from '../../components/text';
import CTA from '../../components/cta';
import SitterLauren from '../../components/sitter-lauren';
import ImageHeaderSF from '../../components/image-header-sf';
import ImageHIWStepOne from '../../components/image-sitters-hiw-step-one';
import ImageHIWStepTwo from '../../components/image-sitters-hiw-step-two';
import ImageHIWStepThree from '../../components/image-sitters-hiw-step-three';

// -----------------------------------------------------------------------------
// STYLES:
// -----------------------------------------------------------------------------
const BgTop = styled.div`
  background-image: linear-gradient(170deg, #FFFFFF, #FFF8F3);
  height: 580px;
`;
// -----------------------------------------------------------------------------
const Container = styled.div`
  max-width: 450px;
  margin-top: 125px;
`;
// -----------------------------------------------------------------------------
const Left = styled.div`
  max-width: 100%;
`;
// -----------------------------------------------------------------------------
// COMPONENT:
// -----------------------------------------------------------------------------
const SittersDesktop = () => (
  <React.Fragment>
    <BgTop>
      <div className="pt4" />
      <Section viewType="desktop" style={{ height: '100%' }}>
        <div className="clearfix">
          <Left className="sm-hide md-col md-col-7 pr1">
            <div className="relative">
              <ImageHeaderSF
                className="absolute"
                style={{
                  pointerEvents: 'none', // img overlaps submit btn
                  width: 630,
                  bottom: -190,
                  // left: -80,
                }}
              />
            </div>
          </Left>
          <div className="md-col md-col-5">
            <Container>
              <Headline />
              <div className="pt3" />
              <div className="flex justify-center">
                <CTA label="Join our Beta" />
              </div>
            </Container>
          </div>
        </div>
      </Section>
    </BgTop>

    <Section viewType="desktop" className="mb4">
      <div className="pt4" />
      <div className="pt4" />
      <HowItWorks.Headline />
      <div className="pt4" />
      <div className="pt3" />
      <div className="flex justify-center">
        <div style={{ width: '300px' }} className="col-4">
          <ImageHIWStepOne style={{ maxWidth: '300px' }} />
        </div>
        <div className="px3" />
        <div className="col-5 flex flex-column justify-center">
          <HowItWorks.StepOne />
        </div>
      </div>

      <div className="pt3" />
      <div className="flex justify-center">
        <div style={{ width: '300px' }} className="col-4">
          <ImageHIWStepTwo style={{ maxWidth: '300px' }} />
        </div>
        <div className="px3" />
        <div className="col-5 flex flex-column justify-center">
          <HowItWorks.StepTwo />
        </div>
      </div>

      <div className="pt3" />
      <div className="flex justify-center">
        <div style={{ width: '300px' }} className="col-4">
          <ImageHIWStepThree style={{ maxWidth: '300px' }} />
        </div>
        <div className="px3" />
        <div className="col-5 flex flex-column justify-center">
          <HowItWorks.StepThree />
          <div className="flex justify-start mt3">
            <CTA label="Start Searching" />
          </div>
        </div>
      </div>
      <div className="pt4">
        <Text size="L" color="secondary" className={'m0 center'}>What our members say</Text>
      </div>
      <SitterLauren 
      style={{ width: '100px' }} 
      className="col-4" 
      text={`“Pet Digs has been an awesome solution for someone like me who works remotely, loves animals, but current situation doesn’t allow for them. I’d highly recommend to any digital nomads, or people who are in a similar position as me and just want to be near some furry companions!“ -- Lauren`}/>
      <div className="pt3" />
    </Section>
  </React.Fragment>
);

export default SittersDesktop;
