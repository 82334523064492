import React from 'react';
import Text from '../../text';

// -----------------------------------------------------------------------------
// COMPONENTS:
// -----------------------------------------------------------------------------
export const Headline = () => (
  <React.Fragment>
    <Text size="L" color="secondary" className="m0 mx-auto center">
      How It Works
    </Text>
    <div className="flex justify-center">
      <Text className="m0 mt1 center" style={{ maxWidth: '820px' }}>
        Look after a beloved pet in exchange for free accommodation on our web app!
        <br/>
        Sitters can be travelers on vacation/business, digital nomads, locals, someone new to the city, etc. (only available to US residents).  
      </Text>
    </div>
  </React.Fragment>
);

export const StepOne = ({ center, ...rest }) => (
  <div {...rest}>
    <Text size="L" color="secondary" className={`m0 ${ center ? 'center' : '' }`}>
    1. Explore a new place
    </Text>
    <Text size="S" className={`m0 mt1 ${ center ? 'center' : '' }`}>
      House sitting gives you the opportunity to explore a new place while
     making a furry friend!
    </Text>
  </div>
);

export const StepTwo = ({ center, ...rest }) => (
  <div {...rest}>
    <Text size="L" color="secondary" className={`m0 ${ center ? 'center' : '' }`}>
      2. Make your profile shine
    </Text>
    <Text size="S" className={`m0 mt1 ${ center ? 'center' : '' }`}>
      Introduce yourself, talk about your past pet
      experience and be sure to add photos (it helps to
      include furry friends!).
    </Text>
  </div>
);

export const StepThree = ({ center, ...rest }) => (
  <div {...rest}>
    <Text size="L" color="secondary" className={`m0 ${ center ? 'center' : '' }`}>
      3. Connect with pet owners
    </Text>
    <Text size="S" className={`m0 mt1 ${ center ? 'center' : '' }`}>
      Apply to listings and message pet owners to see if it's a good fit. Then, work out logistics and get ready for your pet sit!
    </Text>
  </div>
);
