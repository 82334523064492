import React from 'react';
import Text from '../../text';

// -----------------------------------------------------------------------------
// COMPONENT:
// -----------------------------------------------------------------------------
const Headline = () => (
  <React.Fragment>
    <Text size="L" className="m0 center">
      Find a Free Place to
      <br />
      Stay with Some
      <br />
      Snuggles and Purrs!
    </Text>
    <Text color="secondary" className="m0 mt1 center">
      Now in the San Francisco Bay Area. 
      <br/>
      Membership during the Beta is free!
    </Text>
  </React.Fragment>
);

export default Headline;
