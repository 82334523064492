import React from 'react';
import styled from 'styled-components';
import Section from '../../components/section';
import Headline from '../../components/sitters/headline';
import * as HowItWorks from '../../components/sitters/how-it-works';
import Text from '../../components/text';
import CTA from '../../components/cta';
import ImageHeaderSF from '../../components/image-header-sf';
import ImageHIWStepOne from '../../components/image-sitters-hiw-step-one';
import ImageHIWStepTwo from '../../components/image-sitters-hiw-step-two';
import ImageHIWStepThree from '../../components/image-sitters-hiw-step-three';
import SitterLauren from '../../components/sitter-lauren';

// -----------------------------------------------------------------------------
// STYLES:
// -----------------------------------------------------------------------------
const BgTop = styled.div`
  background-image: linear-gradient(170deg, #FFFFFF, #FFF8F3);
`;
// -----------------------------------------------------------------------------
// COMPONENT:
// -----------------------------------------------------------------------------
const SittersMobile = () => (
  <React.Fragment>
    <BgTop>
      <Section viewType="mobile" style={{ height: '100%' }}>
        <div className="pt4" />
        <div className="pt4" />
        <Headline />
        <div className="pt3" />
        <div className="flex justify-center">
          <CTA label="Join our Beta" />
        </div>
        <div className="pt3" />
        <div className="relative">
          <ImageHeaderSF
            className="absolute"
            style={{
              width: '100%',
              bottom: -34,
            }}
          />
        </div>
      </Section>
    </BgTop>

    <Section viewType="mobile" className="mb4">
      <div className="pt4" />
      <div className="pt4" />
      <HowItWorks.Headline />
      <div className="pt4" />
      <div className="pt3" />
      <HowItWorks.StepOne center />
      <div className="pt3" />
      <ImageHIWStepOne style={{ maxWidth: '300px' }} className="mx-auto" />

      <div className="pt3" />
      <HowItWorks.StepTwo center />
      <div className="pt3" />
      <ImageHIWStepTwo style={{ maxWidth: '300px' }} className="mx-auto" />

      <div className="pt3" />
      <HowItWorks.StepThree center />
      <div className="pt3" />
      <ImageHIWStepThree style={{ maxWidth: '300px' }} className="mx-auto" />
      <div className="flex justify-center pt4">
        <CTA label="Start Searching" />
      </div>
      <div className="pt4">
        <Text size="L" color="secondary" className={'m0 center'}>What our members say</Text>
      </div>
      <SitterLauren 
      style={{ width: '100px' }} 
      className="col-4" 
      text={`“Pet Digs has been an awesome solution for someone like me who works remotely, loves animals, but current situation doesn’t allow for them. I’d highly recommend to any digital nomads, or people who are in a similar position as me and just want to be near some furry companions!“ -- Lauren`}/>
      <div className="pt3" />
    </Section>
  </React.Fragment>
);

export default SittersMobile;
