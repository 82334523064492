import React from 'react';
import ErrorBoundary from '../components/error-boundary';
import Layout from '../components/layout';
import SittersMobile from '../mobile-sections/sitters';
import SittersDesktop from '../desktop-sections/sitters';

// -----------------------------------------------------------------------------
// COMPONENT:
// -----------------------------------------------------------------------------
const SittersPage = ({ location }) => (
  <ErrorBoundary>
    <Layout location={location}>
      <div className="hide-for-large">
        <SittersMobile />
      </div>
      <div className="show-for-large">
        <SittersDesktop />
      </div>
    </Layout>
  </ErrorBoundary>
);

export default SittersPage;
